<!-- =============================================================================== -->
<!-- ================================== ATTENTION ================================== -->
<!-- =============================================================================== -->

<!-- THIS IS A LEGACY COMPONENT AND IS NO LONGER BEING USED. IT IS BEING KEPT HERE FOR REFERENCE -->
<!-- It was replaced by SettingsDrawer.vue at end of Q4 2024 -->

<template>
  <!-- Account View -->
  <MainLayout>
    <!-- Toolbar -->
    <template #toolbar>
      <div class="flex flex-col pt-5">
        <div class="px-8">
          <div class="text-3xl font-semibold">
            Account Settings
          </div>

          <div class="text-gray-500">
            Having trouble? Contact support through the live chat.
          </div>
        </div>

        <!-- Tabs -->
        <div
          class="w-full border-b mt-4 px-8"
        >
          <nav
            v-if="getUser.expert"
            class="flex"
          >
            <div class="flex items-end space-x-8">
              <div
                class="cursor-pointer border-b-2 border-white transition duration-300 text-gray-500  whitespace-nowrap pb-4 px-1 font-medium text-sm"
                :class="selectedTab === 'general' ? 'border-blue-500 text-blue-600' : 'hover:text-gray-700 hover:border-gray-300 '"
                @click="selectedTab = 'general'"
              >
                General
              </div>

              <div
                class="cursor-pointer border-b-2 border-white transition duration-300 text-gray-500  whitespace-nowrap pb-4 px-1 font-medium text-sm"
                :class="selectedTab === 'profile' ? 'border-blue-500 text-blue-600' : 'hover:text-gray-700 hover:border-gray-300 '"
                @click="selectedTab = 'profile'"
              >
                Expert Profile
              </div>
            </div>
          </nav>
        </div>
      </div>
    </template>

    <template #content>
      <div
        v-if="selectedTab === 'general'"
        style="max-width: 1000px"
      >
        <transition
          name="fade"
          mode="out-in"
        >
          <section aria-labelledby="payment-details-heading">
            <div class="shadow sm:rounded-md sm:overflow-hidden">
              <div class="bg-white py-6 px-4 sm:p-6">
                <div
                  class="flex items-center gap-2"
                >
                  <input
                    :id="'fileInput'"
                    type="file"
                    hidden
                    @change="handleUploadFile($event)"
                  >
                  <label
                    for="fileInput"
                    :class=" !avatarUpload ? 'flex items-center justify-center border border-border-hover rounded-full cursor-pointer w-max' : 'h-10 w-10 flex items-center justify-center rounded-full'"
                  >
                    <svg
                      v-if="!currentUser.avatar"
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      class="h-10 w-10 p-1.5"
                    >
                      <path
                        d="M14.0169 10.3144H6.94581M10.4813 13.8499V6.77882M18.1897 10.314C18.1897 14.5712 14.7385 18.0223 10.4813 18.0223C6.22415 18.0223 2.77301 14.5712 2.77301 10.314C2.77301 6.05678 6.22415 2.60565 10.4813 2.60565C14.7385 2.60565 18.1897 6.05678 18.1897 10.314Z"
                        stroke="#5E6678"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                    <img
                      v-else-if="avatarPreview"
                      :src="avatarPreview"
                      alt="File Preview"
                      class=" h-10 w-10 flex items-center justify-center rounded-full border border-border-hover cursor-pointer hover:opacity-75"
                    >
                    <img
                      v-else-if="currentUser.avatar"
                      :src="currentUser.avatar"
                      alt="File Preview"
                      class="h-10 w-10 flex items-center justify-center rounded-full border border-border-hover cursor-pointer hover:opacity-75"
                    >
                  </label>
                  <div>
                    <h2
                      id="payment-details-heading"
                      class="text-lg leading-6 font-medium text-gray-900"
                    >
                      Account details
                    </h2>
                    <p class="mt-1 text-sm text-gray-500">
                      Update your Account information.
                    </p>
                  </div>
                </div>

                <div class="mt-6 grid grid-cols-4 gap-6">
                  <div class="col-span-4 sm:col-span-2">
                    <BaseInput
                      v-model="currentUser.first_name"
                      label="First Name"
                      placeholder="John"
                    />
                  </div>

                  <div class="col-span-4 sm:col-span-2">
                    <BaseInput
                      v-model="currentUser.last_name"
                      label="Last Name"
                      placeholder="Smith"
                    />
                  </div>

                  <div class="col-span-4 sm:col-span-2">
                    <BaseInput
                      v-model="currentUser.email"
                      label="Email"
                      icon
                      placeholder="username@youremail.com"
                      @input="validateEmail"
                    >
                      <template #icon>
                        <i class="fas fa-envelope text-gray-400" />
                      </template>
                    </BaseInput>
                    <div
                      v-if="emailError"
                      class="text-red-500"
                    >
                      {{ emailError }}
                    </div>
                  </div>

                  <div class="col-span-4 sm:col-span-2">
                    <BaseInput
                      v-model="currentUser.phone_number"
                      label="Phone Number"
                      placeholder="+1 (888) 888 - 8888"
                      @input="validatePhoneNumber"
                    />
                    <div
                      v-if="phoneNumberError"
                      class="text-red-500"
                    >
                      {{ phoneNumberError }}
                    </div>
                  </div>

                  <div
                    class="text-blue-600 underline cursor-pointer"
                    @click="resetPassword"
                  >
                    Reset Password
                  </div>
                </div>
              </div>
              <div class="px-4 py-3 bg-gray-50 text-right sm:px-6 flex justify-end">
                <BaseButton
                  primary
                  :loading="loadingSave"
                  :disabled="loadingSave"
                  @click="handleSaveUser"
                >
                  Save
                </BaseButton>
              </div>
            </div>
          </section>
        </transition>
        <transition
          name="fade"
          mode="out-in"
          v-if="getTeam"
        >
          <section class="my-5">
            <WhiteLabelShare
              :current-user="currentUser"
              :current-team="getTeam"
            />
          </section>
        </transition>
        <transition
          name="fade"
          mode="out-in"
        >
          <!-- Plan -->
          <section
            aria-labelledby="plan-heading"
            class="my-5"
          >
            <ProvidePaypal />
          </section>
        </transition>
        <transition
          name="fade"
          mode="out-in"
        >
          <!-- Plan -->
          <section
            aria-labelledby="plan-heading"
            class="my-5"
          >
            <StripeUpdateDetails v-if="showStripe && getSubscriptionState !== 'free' " />
            <!-- <StripeElement v-if="showStripe" /> -->
            <div
              v-if="showStripe && !getTeam"
              class="bg-white p-4 rounded-b-lg"
            >
              <!-- Current Account -->
              <!-- Go to accoubnt view -->
              <router-link :to="{ name: 'PaymentView' }">
                <BaseButton primary>
                  Change Subscription
                </BaseButton>
              </router-link>
            </div>

            <Invoices v-if="showStripe" />
            <BaseButton
              class="bg-red-100 text-red-700 my-5"
              @click="handleLogout"
            >
              Logout
            </BaseButton>
          </section>
        </transition>
      </div>

      <ExpertsProfileInformation v-else />
      <TeamCreatedModal
        v-if="showCreatedTeamModal"
        @close="showCreatedTeamModal = false"
      />
    </template>
  </MainLayout>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { nanoid } from 'nanoid'
import firebase from '@/api/config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'

import MainLayout from '../../layouts/MainLayout'
import StripeElement from '../../components/account/Stripe'
import StripeUpdateDetails from '../../components/account/StripeUpdateDetails'
import Invoices from '../../components/account/Invoices'
import CreateTeam from '../../components/account/teams/CreateTeam'
import ExpertsProfileInformation from '../../components/account/profile/ExpertsProfileInformation.vue'
import TeamCreatedModal from '../../components/account/TeamCreatedModal.vue'
import ProvidePaypal from '../../components/account/profile/ProvidePaypal.vue'
import WhiteLabelShare from '../../components/account/whiteLabel/WhiteLabelShare.vue'
export default {
  name: 'AccountView',
  components: {
    ProvidePaypal,
    MainLayout,
    StripeElement,
    StripeUpdateDetails,
    Invoices,
    CreateTeam,
    ExpertsProfileInformation,
    TeamCreatedModal,
    WhiteLabelShare,
  },
  data () {
    return {
      currentUser: {},
      loadingSave: false,
      selectedTab: 'general',
      showStripe: false,
      showCreatedTeamModal: false,
      avatarPreview: '',
      avatarUpload: '',
      emailError: '',
      phoneNumberError: ''
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser', 'getTeam', 'getUserSubscription', 'isFreeTier', 'getUserFreeTrialDate']),
    // Check if email is valid
    isEmailValid () {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailRegex.test(this.currentUser.email)
    },
    // Check if phone number is valid
    isPhoneNumberValid () {
      const phoneNumberRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
      return phoneNumberRegex.test(this.currentUser.phone_number) || !this.currentUser.phone_number
    },
    showCreateTeam () {
      return !this.getTeam
    },
    getSubscriptionState () {
      if (this.getUserSubscription.product) { return this.getUserSubscription.product.name }
      if (this.isFreeTier && Date.now() > this.getUserFreeTrialDate + 6.048e8) { return 'expired' }
      return 'free'
    }
  },
  mounted () {
    this.currentUser = {
      ...this.getUser,
      first_name: this.getUser.first_name || this.getUser.name
    }

    if (!this.getTeam) {
      this.showStripe = true
    } else {
      this.showStripe = this.getUser.user_id === this.getTeam.created_by
    }
  },
  methods: {
    ...mapActions('AuthModule', ['logoutUser']),
    ...mapMutations('AuthModule', ['SET_USER']),
    // Handle Logout User
    handleLogout () {
      this.logoutUser()
    },
    // Method to validate email while typing
    validateEmail () {
      if (!this.isEmailValid) {
        this.emailError = 'Please enter a valid email address.'
      } else {
        this.emailError = '' // Clear error message
      }
    },
    // Method to validate phone number while typing
    validatePhoneNumber () {
      if (!this.isPhoneNumberValid) {
        this.phoneNumberError = 'Please enter a valid phone number.'
      } else {
        this.phoneNumberError = '' // Clear error message
      }
    },
    handleUploadFile (event) {
      const validFile = event.target.files[0].type === 'image/png' || event.target.files[0].type === 'image/jpeg'

      if (!validFile) {
        this.$showAlert({
          message: 'Invalid File Type',
          type: 'error'
        })

        return
      }
      if (event.target?.files?.length === 0) return
      this.avatarUpload = event.target.files[0]
      this.avatarPreview = event.target.files[0]
      if (this.avatarUpload) {
        const reader = new FileReader()
        reader.onload = e => {
          this.avatarPreview = e.target.result
        }
        reader.readAsDataURL(this.avatarPreview)
      }
    },
    async resetPassword () {
      // Get the current user
      const user = firebase.auth().currentUser

      try {
        // Generate a password reset link for the user
        await firebase.auth().sendPasswordResetEmail(user.email)

        this.$showAlert({
          message: 'Password reset link sent to your email',
          type: 'success'
        })
      } catch (error) {
        // An error occurred while generating the password reset link
        this.$showAlert({
          message: error,
          type: 'error'
        })
      }
    },
    // Handle Save User
    async handleSaveUser () {
      // Check if email is valid
      if (!this.isEmailValid) {
        this.emailError = 'Please enter a valid email address.'
        return
      } else {
        this.emailError = '' // Clear error message
      }

      // Check if phone number is valid
      if (!this.isPhoneNumberValid) {
        this.phoneNumberError = 'Please enter a valid phone number.'
        return
      } else {
        this.phoneNumberError = '' // Clear error message
      }

      this.loadingSave = true

      try {
        const db = firebase.firestore()

        if (this.currentUser.email !== this.getUser.email) {
          const user = firebase.auth().currentUser
          await user.updateEmail(this.currentUser.email)
        }
        const newName = `${this.currentUser.first_name} ${this.currentUser.last_name}`
        await db.collection('auth-users').doc(this.currentUser.user_id).set({
          ...this.getUser,
          ...this.currentUser,
          name: newName
        })

        this.SET_USER({
          ...this.getUser,
          ...this.currentUser,
          name: newName,
          token: this.getUser.uid
        })

        if (this.avatarUpload) {
          if (this.currentUser.avatar) {
            this.deleteOldAvatar(this.currentUser.avatar)
          }
          const folderName = nanoid()
          const fileName = nanoid()
          const filePath = `${folderName}/${fileName}`

          const storageRef = firebase.app().storage('gs://foreplay-user-avatars').ref(filePath)

          const firstFile = this.avatarUpload
          await storageRef.put(firstFile)

          const fileLocation = `https://storage.googleapis.com/foreplay-user-avatars/${filePath}`

          const toUpdate = {
            avatar: fileLocation
          }
          this.SET_USER({
            ...this.getUser,
            ...toUpdate
          })
          // Update the experts avatar in firebase and the user avatar
          const user = firebase.auth().currentUser
          await FirebaseAPI.Users.update(user.uid, toUpdate)

          // Show that all files are uploaded
          this.$showAlert({
            message: 'Profile Image Updated',
            type: 'success'
          })
        }

        this.$showAlert({
          message: 'Account details updated',
          type: 'success'
        })
      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: e,
          type: 'error'
        })
      } finally {
        this.loadingSave = false
      }
    },
    async deleteOldAvatar (filePath) {
      const storageRef = firebase.app().storage('gs://foreplay-user-avatars').ref()
      const oldAvatarRef = storageRef.child(filePath.split('https://storage.googleapis.com/foreplay-user-avatars/')[1])
      await oldAvatarRef.delete()
    }
  }
}
</script>
