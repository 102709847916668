<!-- =============================================================================== -->
<!-- ================================== ATTENTION ================================== -->
<!-- =============================================================================== -->

<!-- THIS IS A LEGACY COMPONENT AND IS NO LONGER BEING USED. IT IS BEING KEPT HERE FOR REFERENCE -->
<!-- It was replaced by TeamSettings.vue in the settings drawer as of 1/18/2025 -->

<template>
  <div
    class="bg-white space-y-6 px-4 p-6 shadow sm:rounded-md sm:overflow-hidden"
  >
    <form>
      <div class="space-y-6">
        <div>
          <h1 class="text-lg leading-6 font-medium text-gray-900">
            Team Settings
          </h1>
          <p class="mt-1 text-sm text-gray-500">
            Let’s get started by naming your team
          </p>
        </div>

        <div>
          <h2 class="text-md leading-6 font-medium text-gray-900">
            Team Name
          </h2>
          <p class="mt-1 text-sm text-gray-500">
            Name your team
          </p>
          <div class="flex">
            <BaseInput
              v-model="teamName"
              class="w-1/3"
              placeholder="Your team name"
            />
          </div>
          <BaseButton
            :disabled="!teamName.length"
            :loading="loading"
            class="mt-3"
            primary
            @click="createTeam"
          >
            Create Team
          </BaseButton>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import FirebaseAPI from '@/api/firebase'
import API from '@/api'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CreateTeam',
  data () {
    return {
      loading: false,
      teamName: ''
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getTeam'])
  },
  methods: {
    ...mapActions('AuthModule', ['fetchUserTeam']),
    async createTeam () {
      this.loading = true
      await FirebaseAPI.Teams.create(this.teamName)

      this.$emit('createdTeam')

      // set the team in global state
      await this.fetchUserTeam()

      // migrate users assets to their team
      await API.Teams.migrateUserToTeam(this.getTeam.id)

      this.loading = false
    }
  }
}
</script>

<style scoped></style>
